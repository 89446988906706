const config = {
    // baseUrl: 'https://d3-api.bbkoreabit.com/api/agent/v1/',
    baseUrl: 'https://api.m5-solution.com/api/agent/v1/',
    maxSizeUploadAvatar: 1048576 // bytes
}

export const arrColors = [
    '#a7d676',
    '#ff8357',
    '#a8dee0',
    '#fac172',
    '#757575',
    '#ede1e5',
    '#dae6e4',
    '#ffe5c0',
    '#90c4e9',
    '#fea78c'
]

export default config
